<template>
  <Toast position="top-center" />
  <div class="flex flex-wrap align-items-center justify-content-center p-fluid">
    <div class="col-12 md:col-5">
      <div class="card">
        <div class="grid formgrid">
          <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
            <span class="p-input-icon-left p-float-label">
              <i class="pi pi-lock" />
              <InputText type="password" id="shop_hash" v-model="shopHash" @keyup.enter="onLogin" />
              <label for="shop_hash">門市編號</label>
            </span>
          </div>
          <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
            <Button label="登入" class="p-button-purpply p-button mr-2" @click="onLogin" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { getPurchasableProducts } from '@/api'

export default {
  data() {
    return {
      shopHash: ""
    }
  },
  methods: {
    onLogin() {
      getPurchasableProducts(this.shopHash)
        .then((response) => {
          localStorage.setItem('shop_id', this.shopHash)
          this.$router.push('/overview/manufacture')
        })
        .catch((err) => {
          this.$toast.add({ severity: 'error', summary: '', detail: '查無此門市', life: 3000 })
          console.log(err.response)
        });
    }
  },
};
</script>

<style scoped lang="scss">
.btn {
  border-radius: 10px;
  border-bottom: 0px;
  display: inline-block;
  padding: 10px;
  position: relative;
  text-align: center;
  transition: background 600ms ease, color 600ms ease;
}

input[type="radio"].toggle {
  display: none;

  &+label {
    cursor: pointer;
    min-width: 120px;
    background-color: var(--purpply-unselected-color);

    &:hover {
      background: none;
      color: var(--purpply-primary-color);
    }

    &:after {
      background-color: var(--purpply-primary-color);
      content: "";
      height: 100%;
      position: absolute;
      top: 0;
      transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
      width: 100%;
      z-index: -1;
    }
  }

  &.toggle-left+label {
    border-right: 0;

    &:after {
      left: 100%;
    }
  }

  &.toggle-right+label {
    margin-left: 0px;

    &:after {
      left: -100%;
    }
  }

  &:checked+label {
    cursor: default;
    color: #fff;
    background-color: var(--purpply-primary-color);
    transition: color 200ms;

    &:after {
      left: 0;
    }
  }
}
</style>
